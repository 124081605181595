exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-document-tsx": () => import("./../../../src/pages/document.tsx" /* webpackChunkName: "component---src-pages-document-tsx" */),
  "component---src-pages-faculties-tsx": () => import("./../../../src/pages/faculties.tsx" /* webpackChunkName: "component---src-pages-faculties-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-testimonials-tsx": () => import("./../../../src/pages/testimonials.tsx" /* webpackChunkName: "component---src-pages-testimonials-tsx" */),
  "component---src-templates-document-tsx": () => import("./../../../src/templates/document.tsx" /* webpackChunkName: "component---src-templates-document-tsx" */),
  "component---src-templates-order-tsx": () => import("./../../../src/templates/order.tsx" /* webpackChunkName: "component---src-templates-order-tsx" */)
}

